/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Loader from './Loader'
import { loadFeatureFlags, updateFeatureFlag } from '../actions/HotelActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { loadIntegratedPlatforms } from '../actions/HotelPlatformActions'
import { FLAGS_WITH_SMS_WHTSP_DEPENDENCY } from '../constants/featureFlags'
import alertify from 'alertifyjs'

class HotelFeatureFlags extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      expandedCategories: []
    }
  }

  componentDidMount() {
    const { dispatch, selectedHotelId } = this.props

    this.setState({ isLoading: true })
    dispatch(
      loadFeatureFlags(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
    dispatch(loadIntegratedPlatforms(selectedHotelId))

    this.preloadImage('/icons/select-tick.svg')
  }

  preloadImage = src => {
    const img = new Image()
    img.src = src
  }

  sendUpdateRequest = (flagKey, flag) => {
    const { dispatch, selectedHotelId } = this.props
    const newFlagValue = !flag.value

    const featureFlags = { [flagKey]: { value: newFlagValue } }

    this.setState({ updatingFlag: flagKey, error: null })
    dispatch(
      updateFeatureFlag(selectedHotelId, flagKey, featureFlags, (statusCode, body) => {
        this.setState({ updatingFlag: false })
        if (statusCode !== 200) {
          return this.setState({ error: body && body.error })
        }
      })
    )
  }

  handleWarningsAndAlerts = (flagKey, flag) => {
    const platforms = Array.from(this.props.hotelPlatforms.values()).map(platform =>
      platform.toJS()
    )
    const isSmsPlatformAdded = platforms.some(platform => platform.apiUrlKey === 'nexmo')
    const isWhatsappPlatformAdded = platforms.some(
      platform => platform.apiUrlKey === 'message_bird'
    )

    if (!isSmsPlatformAdded && !isWhatsappPlatformAdded) {
      alertify.error(
        'No messaging platforms found. Add SMS or WhatsApp or both platforms to turn on this feature.'
      )
      return true
    } else if (!isSmsPlatformAdded) {
      alertify.confirm(
        'SMS platform not found',
        `Guests added from PMS will not have SMS enabled. If you don't intend to use SMS, press "OK" to turn on this feature.`,
        () => this.sendUpdateRequest(flagKey, flag),
        () => {}
      )
      return true
    } else if (!isWhatsappPlatformAdded) {
      alertify.confirm(
        'MessageBird platform not found',
        `Guests added from PMS will not have WhatsApp enabled. If you don't intend to use WhatsApp, press "OK" to turn on this feature.`,
        () => this.sendUpdateRequest(flagKey, flag),
        () => {}
      )
      return true
    }

    return false
  }

  toggleFlag = (flagKey, flag) => ev => {
    const newFlagValue = !flag.value
    if (FLAGS_WITH_SMS_WHTSP_DEPENDENCY.includes(flagKey) && newFlagValue === true) {
      const hadWarning = this.handleWarningsAndAlerts(flagKey, flag)
      if (hadWarning) return
    }

    this.sendUpdateRequest(flagKey, flag)
  }

  toggleCategory = category => {
    this.setState(prevState => ({
      expandedCategories: prevState.expandedCategories.includes(category)
        ? prevState.expandedCategories.filter(c => c !== category)
        : [...prevState.expandedCategories, category]
    }))
  }

  render() {
    const { featureFlags } = this.props
    const { isLoading, updatingFlag, error } = this.state

    const sortedFlags = featureFlags.sortBy(
      (flag, key) => _.toLower(flag.get('display_name')) || key
    )
    const categories = sortedFlags.groupBy(flag => flag.get('category')).toJS()
    categories['Other Features'] = { ...categories['Other Features'], ...categories['null'] }
    delete categories['null']

    return (
      <div className="section noborder">
        <Loader isLoading={isLoading} />

        <h3 className="bmargin0-5em">Feature Flags </h3>

        <div className="feature-flags-wrapper">
          {Object.keys(categories).map(category => (
            <div key={category}>
              <h4 className="sub-heading" onClick={() => this.toggleCategory(category)}>
                {category}
                <FontAwesomeIcon
                  className={classNames('arrow', {
                    expanded: this.state.expandedCategories.includes(category),
                    collapsed: !this.state.expandedCategories.includes(category)
                  })}
                  icon={faCaretDown}
                />
              </h4>
              {this.state.expandedCategories.includes(category) &&
                Object.entries(categories[category]).map(([key, flag]) => (
                  <div
                    key={key}
                    className={`flag-item ${updatingFlag === key && 'custom-disabled'}`}
                    onClick={this.toggleFlag(key, flag)}
                  >
                    <div className={`flag-select ${flag.value ? 'selected' : ''}`}>
                      {flag.value && <img src={`/icons/select-tick.svg`} />}
                    </div>
                    <div className="flag-name">{flag.display_name || key.replace(/_/g, ' ')}</div>
                    <div className="description" title={flag.description}>
                      {flag.description}
                    </div>
                  </div>
                ))}
            </div>
          ))}
          {error && <div className="error tmargin1em">{error}</div>}
        </div>
      </div>
    )
  }
}

export default HotelFeatureFlags
