/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, September 2019
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { fromJS, is, List } from 'immutable'
import alertify from 'alertifyjs'
import _ from 'lodash'
import moment from 'moment'

import {
  saveIntegration,
  verifyIntegration,
  loadVerifiedIntegrationBySource,
  stopVerifiedIntegrationProcess,
  getIntegrationCredentialsStatus
} from '../actions/HotelIntegrationActions'
import HotelUtils from '../utils/HotelUtils'
import Endpoints from '../constants/Endpoints'

const BOOK4TIME = 'BOOK4TIME'
const KNOWCROSS = 'KNOWCROSS'
const KNOWCROSS_V2 = 'KNOWCROSS_V2'
const ACTABL = 'ACTABL'
const INFOR = 'INFOR'
const ORACLE_OXI = 'ORACLE_OXI'
const ORACLE_OHIP = 'ORACLE_OHIP'
const SHIJI = 'SHIJI'
const MESSAGE_BOX = 'MESSAGE_BOX'
const CENDYN = 'CENDYN'
const SEVENROOMS = 'SEVENROOMS'
const API_ENDPOINT = process.env.REACT_APP_GLOWING_API
const { API } = Endpoints

class IntegrationCredentialsStatus extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      integrationErrorMessage: 'Pending'
    }
  }

  componentDidMount() {
    const { integration } = this.props
    if (integration.get('id')) {
      this.getIntegrationCredentialsStatus()
    }
  }

  getIntegrationCredentialsStatus = () => {
    const { dispatch, integration } = this.props
    const selectedHotelId = integration.get('hotel_id')

    this.setState({ integrationUpdatingStatus: true, integrationValidCredentials: false })
    dispatch(
      getIntegrationCredentialsStatus(integration, selectedHotelId, (statusCode, body) => {
        if (statusCode !== 200 && statusCode !== 201) {
          this.setState({
            integrationUpdatingStatus: false,
            integrationValidCredentials: false,
            integrationErrorMessage: body
          })
          return this.setState({ error: body && fromJS(body.error) })
        }

        this.setState({ integrationUpdatingStatus: false, integrationValidCredentials: true })
      })
    )
  }

  render() {
    const {
      integrationValidCredentials,
      integrationUpdatingStatus,
      integrationErrorMessage
    } = this.state

    return (
      <fieldset>
        <div className="integration-wrapper">
          <div className="integration-status">
            <label
              className={`integration-credentials-input ${
                integrationUpdatingStatus ? '' : integrationValidCredentials ? 'valid' : 'invalid'
              } button button-secondary`}
            >
              <strong>
                <span className="integration-credentials-status-input">
                  {integrationUpdatingStatus
                    ? 'Updating...'
                    : `Integration credentials status: ${
                        integrationValidCredentials ? 'Working' : integrationErrorMessage
                      }`}
                </span>
              </strong>
              <input
                title="Click to see the latest credentials status"
                id="integration-credentials-status"
                name="integration-credentials-status"
                onClick={this.getIntegrationCredentialsStatus}
              />
            </label>
          </div>
        </div>
      </fieldset>
    )
  }
}

class FormFields extends React.Component {
  componentDidMount = () => {
    const { integration, isEditing, hotelId } = this.props
    let integrationName = HotelUtils.getIntegrationName(integration.get('integration_type'))
    const oracleOxiUrl = `${API}/v1/hotels/${hotelId}/integrations/opera/reservations`
    const actablWebhookUrl = `${API}/v1/hotels/${hotelId}/ticketing/actabl/webhook`
    const cendynWebhookUrl = `${API}/v1/hotels/${hotelId}/integrations/cendyn/webhook`

    if (isEditing) {
      const integrationData = integration.get('integration_data')
      const initialData = {
        username: integrationData.get(`${integrationName}_ENDPOINT_USERNAME`),
        password:
          integrationData.get(`${integrationName}_ENDPOINT_PASSWORD`) ||
          integrationData.get('password'),
        endpoint_url: integrationData.get(`${integrationName}_ENDPOINT_URL`),
        endpoint_path: integrationData.get(`${integrationName}_ENDPOINT_PATH`),
        property_code: integrationData.get(`PROPERTY_CODE`),
        glowing_username: integrationData.get(`GLOWING_INFOR_USERNAME`),
        glowing_password: integrationData.get(`GLOWING_INFOR_PASSWORD`),
        hotel_id: integrationData.get(`${integrationName}_HOTEL_ID`),
        api_key: integrationData.get(`${integrationName}_API_KEY`),
        api_secret: integrationData.get(`${integrationName}_API_SECRET`),
        authorization: integrationData.get(`${integrationName}_AUTHORIZATION`),
        property_id: integrationData.get(`${integrationName}_PROPERTY_ID`),
        public_key: integrationData.get(`${integrationName}_PUBLIC_KEY`),
        private_key: integrationData.get(`${integrationName}_PRIVATE_KEY`),
        channel_id: integrationData.get(`${integrationName}_CHANNEL_ID`),
        ticket_config: integrationData.get(`${integrationName}_TICKET_CONFIG`),
        app_key: integrationData.get(`${integrationName}_APP_KEY`),
        external_system: integrationData.get(`${integrationName}_EXTERNAL_SYSTEM`),
        client_id: integrationData.get(`${integrationName}_CLIENT_ID`),
        client_secret: integrationData.get(`${integrationName}_CLIENT_SECRET`),
        enterprise_id: integrationData.get(`${integrationName}_ENTERPRISE_ID`),
        scope: integrationData.get(`${integrationName}_SCOPE`),
        location_id: integrationData.get(`${integrationName}_LOCATION_ID`),
        user_id: integrationData.get(`${integrationName}_USER_ID`),
        account_id: integrationData.get(`${integrationName}_ACCOUNT_ID`),
        api_master_secret: integrationData.get(`${integrationName}_API_MASTER_SECRET`),
        account_token: integrationData.get('account_token'),
        api_token: integrationData.get('api_token'),
        base_url: integrationData.get('base_url'),
        login_id: integrationData.get('login_id'),
        machine_id: integrationData.get('machine_id'),
        num_days_till_token_exp: integrationData.get('num_days_till_token_exp'),
        loyalty_member_field_name: integrationData.get(
          `${integrationName}_LOYALTY_MEMBER_FIELD_NAME`
        ),
        venue_group_id: integrationData.get(`${integrationName}_VENUE_GROUP_ID`)
      }

      if (integrationName === ACTABL) initialData.webhook_url = actablWebhookUrl

      if (integrationName === CENDYN) initialData.webhook_url = cendynWebhookUrl

      this.props.updateIntegration(initialData)
    }

    if (integrationName === ORACLE_OXI) {
      const initialData = {
        endpoint_url: oracleOxiUrl
      }
      this.props.updateIntegration(initialData)
    }

    if (integrationName === ACTABL && !isEditing) {
      const initialData = {
        webhook_url: actablWebhookUrl
      }
      this.props.updateIntegration(initialData)
    }

    if (integrationName === CENDYN && !isEditing) {
      const initialData = {
        webhook_url: cendynWebhookUrl
      }
      this.props.updateIntegration(initialData)
    }
  }

  handleChange = ev => {
    this.props.handleFieldChange(ev.target.name, ev.target.value)
  }

  handleCopy = fieldName => {
    const endpointUrl = this.props.integration.getIn(['integration_data', fieldName])
    navigator.clipboard.writeText(endpointUrl)
    alertify.success('Copied to clipboard')
  }

  isDisabled = () => {
    const { integration } = this.props
    const integrationName = HotelUtils.getIntegrationName(integration.get('integration_type'))

    if (integrationName === ORACLE_OXI) return true

    return false
  }

  render() {
    const { integration, dispatch, error, hotelId } = this.props
    let integrationName = HotelUtils.getIntegrationName(integration.get('integration_type'))

    const integrationData = integration.get('integration_data')

    return (
      <div>
        {(integrationName === INFOR ||
          integrationName === KNOWCROSS ||
          integrationName === SHIJI ||
          integrationName === ORACLE_OHIP) && (
          <div>
            <fieldset className="form-item">
              <legend>Username</legend>
              <input
                type="text"
                name="username"
                onChange={this.handleChange}
                value={integrationData.get('username') || ''}
                className={error && error.has('username') ? 'error' : ''}
                required
              />
            </fieldset>

            <fieldset className="form-item">
              <legend>Password</legend>
              <input
                type="text"
                name="password"
                onChange={this.handleChange}
                value={integrationData.get('password') || ''}
                className={error && error.has('password') ? 'error' : ''}
                required
              />
            </fieldset>
          </div>
        )}

        {integrationName === KNOWCROSS_V2 && (
          <div>
            <fieldset className="form-item">
              <legend>Property ID</legend>
              <input
                type="text"
                name="property_id"
                onChange={this.handleChange}
                value={integrationData.get('property_id') || ''}
                className={error && error.has('property_id') ? 'error' : ''}
                required
              />
            </fieldset>

            <fieldset className="form-item">
              <legend>Public Key</legend>
              <input
                type="text"
                name="public_key"
                onChange={this.handleChange}
                value={integrationData.get('public_key') || ''}
                className={error && error.has('public_key') ? 'error' : ''}
                required
              />
            </fieldset>

            <fieldset className="form-item">
              <legend>Private Key</legend>
              <input
                type="text"
                name="private_key"
                onChange={this.handleChange}
                value={integrationData.get('private_key') || ''}
                className={error && error.has('private_key') ? 'error' : ''}
                required
              />
            </fieldset>
          </div>
        )}

        {integrationName !== BOOK4TIME && integrationName !== SEVENROOMS && (
          <fieldset className="form-item">
            <legend>Endpoint URL</legend>
            <div className="input-endpoint-url-wrapper">
              <input
                type="text"
                name="endpoint_url"
                onChange={this.handleChange}
                value={integrationData.get('endpoint_url') || ''}
                className={error && error.has('endpoint_url') ? 'error' : 'input-endpoint-url'}
                required
                disabled={this.isDisabled()}
              />
              {integrationName === ORACLE_OXI && (
                <button
                  type="button"
                  className="button copy-button"
                  onClick={() => this.handleCopy('endpoint_url')}
                >
                  Copy
                </button>
              )}
            </div>
          </fieldset>
        )}

        {integrationName === BOOK4TIME && (
          <div>
            <fieldset className="form-item">
              <legend>Base URL</legend>
              <input
                type="text"
                name="base_url"
                onChange={this.handleChange}
                value={integrationData.get('base_url') || ''}
                className={error && error.has('base_url') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Account Token</legend>
              <input
                type="text"
                name="account_token"
                onChange={this.handleChange}
                value={integrationData.get('account_token') || ''}
                className={error && error.has('account_token') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>API Token</legend>
              <input
                type="text"
                name="api_token"
                onChange={this.handleChange}
                value={integrationData.get('api_token') || ''}
                className={error && error.has('api_token') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Login ID</legend>
              <input
                type="text"
                name="login_id"
                onChange={this.handleChange}
                value={integrationData.get('login_id') || ''}
                className={error && error.has('login_id') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Password</legend>
              <input
                type="text"
                name="password"
                onChange={this.handleChange}
                value={integrationData.get('password') || ''}
                className={error && error.has('password') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Machine ID</legend>
              <input
                type="text"
                name="machine_id"
                onChange={this.handleChange}
                value={integrationData.get('machine_id') || ''}
                className={error && error.has('machine_id') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Number of Days Till Token Expiration</legend>
              <input
                type="text"
                name="num_days_till_token_exp"
                onChange={this.handleChange}
                value={integrationData.get('num_days_till_token_exp') || ''}
                className={error && error.has('num_days_till_token_exp') ? 'error' : ''}
                required
              />
            </fieldset>
          </div>
        )}

        {integrationName === INFOR && (
          <div>
            <fieldset className="form-item">
              <legend>Endpoint Path</legend>
              <input
                type="text"
                name="endpoint_path"
                onChange={this.handleChange}
                value={integrationData.get('endpoint_path') || ''}
                className={error && error.has('endpoint_path') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Property Code</legend>
              <input
                type="text"
                name="property_code"
                onChange={this.handleChange}
                value={integrationData.get('property_code') || ''}
                className={error && error.has('property_code') ? 'error' : ''}
                required
              />
            </fieldset>
            {integration.get('id') && (
              <div>
                <fieldset className="form-item">
                  <legend>Glowing Username</legend>
                  <input
                    type="text"
                    value={integrationData.get('glowing_username') || ''}
                    disabled
                  />
                </fieldset>

                <fieldset className="form-item">
                  <legend>Glowing Password</legend>
                  <input
                    type="text"
                    value={integrationData.get('glowing_password') || ''}
                    disabled
                  />
                </fieldset>

                <fieldset className="form-item">
                  <legend>Reservation Endpoint</legend>
                  <input
                    type="text"
                    value={`${API_ENDPOINT}/v1/hotels/${hotelId}/integrations/infor/reservation`}
                    readOnly
                  />
                </fieldset>

                <fieldset className="form-item">
                  <legend>Profile Endpoint</legend>
                  <input
                    type="text"
                    value={`${API_ENDPOINT}/v1/hotels/${hotelId}/integrations/infor/profile`}
                    readOnly
                  />
                </fieldset>
              </div>
            )}
          </div>
        )}

        {integrationName === ACTABL && (
          <div>
            <fieldset className="form-item">
              <legend>Hotel ID</legend>
              <input
                type="text"
                name="hotel_id"
                onChange={this.handleChange}
                value={integrationData.get('hotel_id') || ''}
                className={error && error.has('hotel_id') ? 'error' : ''}
                required
                disabled={this.isDisabled()}
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>API Key</legend>
              <input
                type="text"
                name="api_key"
                onChange={this.handleChange}
                value={integrationData.get('api_key') || ''}
                className={error && error.has('api_key') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Authorization</legend>
              <input
                type="text"
                name="authorization"
                onChange={this.handleChange}
                value={integrationData.get('authorization') || ''}
                className={error && error.has('authorization') ? 'error' : ''}
                required
              />
            </fieldset>
          </div>
        )}

        {integrationName === SHIJI && (
          <div>
            <fieldset className="form-item">
              <legend>Hotel ID</legend>
              <input
                type="text"
                name="hotel_id"
                onChange={this.handleChange}
                value={integrationData.get('hotel_id') || ''}
                className={error && error.has('hotel_id') ? 'error' : ''}
                required
                disabled={this.isDisabled()}
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Channel ID</legend>
              <input
                type="text"
                name="channel_id"
                onChange={this.handleChange}
                value={integrationData.get('channel_id') || ''}
                className={error && error.has('channel_id') ? 'error' : ''}
                required
                disabled={this.isDisabled()}
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>API Key</legend>
              <input
                type="text"
                name="api_key"
                onChange={this.handleChange}
                value={integrationData.get('api_key') || ''}
                className={error && error.has('api_key') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>API Secret</legend>
              <input
                type="text"
                name="api_secret"
                onChange={this.handleChange}
                value={integrationData.get('api_secret') || ''}
                className={error && error.has('api_secret') ? 'error' : ''}
                required
              />
            </fieldset>
          </div>
        )}

        {integrationName === ORACLE_OHIP && (
          <div>
            <fieldset className="form-item">
              <legend>Hotel ID</legend>
              <input
                type="text"
                name="hotel_id"
                onChange={this.handleChange}
                value={integrationData.get('hotel_id') || ''}
                className={error && error.has('hotel_id') ? 'error' : ''}
                required
                disabled={this.isDisabled()}
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>APP Key</legend>
              <input
                type="text"
                name="app_key"
                onChange={this.handleChange}
                value={integrationData.get('app_key') || ''}
                className={error && error.has('app_key') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>External System</legend>
              <input
                type="text"
                name="external_system"
                onChange={this.handleChange}
                value={integrationData.get('external_system') || ''}
                className={error && error.has('external_system') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Client ID</legend>
              <input
                type="text"
                name="client_id"
                onChange={this.handleChange}
                value={integrationData.get('client_id') || ''}
                className={error && error.has('client_id') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Client Secret</legend>
              <input
                type="text"
                name="client_secret"
                onChange={this.handleChange}
                value={integrationData.get('client_secret') || ''}
                className={error && error.has('client_secret') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Enterprise ID</legend>
              <input
                type="text"
                name="enterprise_id"
                onChange={this.handleChange}
                value={integrationData.get('enterprise_id') || ''}
                className={error && error.has('enterprise_id') ? 'error' : ''}
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Scope</legend>
              <input
                type="text"
                name="scope"
                onChange={this.handleChange}
                value={integrationData.get('scope') || ''}
                className={error && error.has('scope') ? 'error' : ''}
              />
            </fieldset>
          </div>
        )}

        {integrationName === MESSAGE_BOX && (
          <div>
            <fieldset className="form-item">
              <legend>Authorization</legend>
              <input
                type="text"
                name="authorization"
                onChange={this.handleChange}
                value={integrationData.get('authorization') || ''}
                className={error && error.has('authorization') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Location ID</legend>
              <input
                type="text"
                name="location_id"
                onChange={this.handleChange}
                value={integrationData.get('location_id') || ''}
                className={error && error.has('location_id') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>User ID</legend>
              <input
                type="text"
                name="user_id"
                onChange={this.handleChange}
                value={integrationData.get('user_id') || ''}
                className={error && error.has('user_id') ? 'error' : ''}
                required
              />
            </fieldset>
          </div>
        )}

        {integrationName === CENDYN && (
          <div>
            <fieldset className="form-item">
              <legend>Account ID</legend>
              <input
                type="text"
                name="account_id"
                onChange={this.handleChange}
                value={integrationData.get('account_id') || ''}
                className={error && error.has('account_id') ? 'error' : ''}
                required
              />
            </fieldset>

            <fieldset className="form-item">
              <legend>API Secret</legend>
              <input
                type="text"
                name="api_secret"
                onChange={this.handleChange}
                value={integrationData.get('api_secret') || ''}
                className={error && error.has('api_secret') ? 'error' : ''}
                required
              />
            </fieldset>

            <fieldset className="form-item">
              <legend>API Master Secret</legend>
              <input
                type="text"
                name="api_master_secret"
                onChange={this.handleChange}
                value={integrationData.get('api_master_secret') || ''}
                className={error && error.has('api_master_secret') ? 'error' : ''}
              />
            </fieldset>

            <fieldset className="form-item">
              <legend>Loyalty Member Field Name</legend>
              <input
                type="text"
                name="loyalty_member_field_name"
                onChange={this.handleChange}
                value={integrationData.get('loyalty_member_field_name') || ''}
                className={error && error.has('loyalty_member_field_name') ? 'error' : ''}
              />
            </fieldset>
          </div>
        )}

        {(integrationName === ACTABL || integrationName === CENDYN) && (
          <div>
            <fieldset className="form-item">
              <legend>Webhook URL</legend>
              <div className="input-webhook-url-wrapper">
                <input
                  type="text"
                  name="webhook_url"
                  value={integrationData.get('webhook_url') || ''}
                  className={error && error.has('webhook_url') ? 'error' : 'input-webhook-url'}
                  required
                  disabled={true}
                />
                <button
                  type="button"
                  className="button copy-button"
                  onClick={() => this.handleCopy('webhook_url')}
                >
                  Copy
                </button>
              </div>
            </fieldset>
          </div>
        )}

        {(integrationName === SHIJI || integrationName === MESSAGE_BOX) && (
          <div>
            <fieldset className="form-item">
              <legend>Ticket Config</legend>
              <textarea
                type="text"
                name="ticket_config"
                onChange={this.handleChange}
                value={integrationData.get('ticket_config') || ''}
                className={error && error.has('ticket_config') ? 'error' : ''}
                required
              />
            </fieldset>
          </div>
        )}

        {integrationName === SEVENROOMS && (
          <div>
            <fieldset className="form-item">
              <legend>Client ID</legend>
              <input
                type="text"
                name="client_id"
                onChange={this.handleChange}
                value={integrationData.get('client_id') || ''}
                className={error && error.has('client_id') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Client Secret</legend>
              <input
                type="text"
                name="client_secret"
                onChange={this.handleChange}
                value={integrationData.get('client_secret') || ''}
                className={error && error.has('client_secret') ? 'error' : ''}
                required
              />
            </fieldset>
            <fieldset className="form-item">
              <legend>Venue Group ID</legend>
              <input
                type="text"
                name="venue_group_id"
                onChange={this.handleChange}
                value={integrationData.get('venue_group_id') || ''}
                className={error && error.has('venue_group_id') ? 'error' : ''}
                required
              />
            </fieldset>
          </div>
        )}

        {(integrationName === KNOWCROSS_V2 ||
          integrationName === SHIJI ||
          integrationName === ORACLE_OHIP ||
          integrationName === MESSAGE_BOX) && (
          <IntegrationCredentialsStatus dispatch={dispatch} integration={integration} />
        )}
      </div>
    )
  }
}

class HotelIntegration extends React.Component {
  static propTypes = {
    integration: PropTypes.object,
    hotelVerifiedIntegration: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      integration: props.integration,
      selectedVerifiedInfo: new List()
    }
  }

  componentDidMount = () => {
    const { dispatch, selectedHotelId } = this.props
    const source = this.state.integration.get('integrationKey')
    dispatch(
      loadVerifiedIntegrationBySource(selectedHotelId, source, (status, body) => {
        this.setState({ selectedVerifiedInfo: fromJS(body.integration_status) })
      })
    )
  }

  handleFieldChange = (fieldName, fieldValue) => {
    const { integration } = this.state
    this.setState({
      integration: integration.setIn(
        ['integration_data', fieldName],
        fieldValue || (fieldValue === '' && null) // Empty string condition is added for isPristine (form validation) to work
      )
    })
  }

  setInitialDataOnEditing = initialData => {
    const { integration } = this.state
    let newIntegration = fromJS({
      integration_data: initialData
    })
    this.setState({
      integration: integration.merge(newIntegration)
    })
  }

  submitForm = (ev, action) => {
    ev.preventDefault()
    const { dispatch, selectedHotelId } = this.props
    const { integration } = this.state
    let integrationName = HotelUtils.getIntegrationName(integration.get('integration_type'))

    this.setState({ saving: true, error: null })
    dispatch(
      saveIntegration(selectedHotelId, integration, (statusCode, body) => {
        this.setState({ saving: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return this.setState({ error: body && fromJS(body.error) })
        }

        alertify.success(
          `Integration successfuly ${integration.get('id') ? 'updated' : 'created'}.`
        )

        integrationName === KNOWCROSS ||
        integrationName === KNOWCROSS_V2 ||
        integrationName === ORACLE_OXI ||
        integrationName === ORACLE_OHIP ||
        integrationName === ACTABL ||
        integrationName === SHIJI ||
        integrationName === MESSAGE_BOX ||
        integrationName === CENDYN ||
        integrationName === SEVENROOMS
          ? setTimeout(() => this.handleCancel(), 500)
          : this.updateInforData(body.infor)
      })
    )
  }

  getIntegrationData = integrationData => {
    return {
      username: integrationData.INFOR_ENDPOINT_USERNAME || '',
      password: integrationData.INFOR_ENDPOINT_PASSWORD || '',
      endpoint_url: integrationData.INFOR_ENDPOINT_URL || '',
      endpoint_path: integrationData.INFOR_ENDPOINT_PATH || '',
      property_code: integrationData.PROPERTY_CODE || '',
      glowing_username: integrationData.GLOWING_INFOR_USERNAME || '',
      glowing_password: integrationData.GLOWING_INFOR_PASSWORD || ''
    }
  }

  updateInforData = infor => {
    infor.uiName = 'Infor'
    infor.integrationKey = INFOR
    infor.integration_data = this.getIntegrationData(infor.integration_data)
    this.setState({
      integration: fromJS(infor)
    })
  }

  handleCancel = ev => {
    ev && ev.preventDefault()

    const { selectedAccountId, selectedHotelId, history } = this.props
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/integrations`)
  }

  isEditing = () => this.state.integration.get('id')

  hasEmptyFields = integrationName => {
    const integrationData = this.state.integration.get('integration_data')
    let isValid =
      integrationName === 'KNOWCROSS_V2'
        ? integrationData.get('public_key') &&
          integrationData.get('private_key') &&
          integrationData.get('endpoint_url')
        : integrationData.get('username') &&
          integrationData.get('password') &&
          integrationData.get('endpoint_url')

    if (integrationName === INFOR) {
      isValid =
        isValid && integrationData.get('endpoint_path') && integrationData.get('property_code')
    } else if (integrationName === ORACLE_OHIP) {
      isValid =
        isValid &&
        integrationData.get('hotel_id') &&
        integrationData.get('app_key') &&
        integrationData.get('external_system') &&
        integrationData.get('client_id') &&
        integrationData.get('client_secret')
    } else if (integrationName === ACTABL) {
      isValid =
        integrationData.get('hotel_id') &&
        integrationData.get('api_key') &&
        integrationData.get('authorization')
    } else if (integrationName === MESSAGE_BOX) {
      isValid = integrationData.get('authorization')
    }

    return !isValid
  }

  isPristine = () => {
    const { integration } = this.state
    const integrationProps = this.props.integration
    const integrationPropsData = integrationProps.get('integration_data')
    const integrationData = integration.get('integration_data')
    const integrationName = HotelUtils.getIntegrationName(integration.get('integration_type'))

    let oldIntegrationData = fromJS({
      username: integrationPropsData.get(`${integrationName}_ENDPOINT_USERNAME`),
      password: integrationPropsData.get(`${integrationName}_ENDPOINT_PASSWORD`),
      endpoint_url: integrationPropsData.get(`${integrationName}_ENDPOINT_URL`)
    })

    let newIntegrationData = fromJS({
      username: integrationData.get('username'),
      password: integrationData.get('password'),
      endpoint_url: integrationData.get('endpoint_url')
    })

    if (integrationName === ORACLE_OHIP) {
      oldIntegrationData = oldIntegrationData
        .set('hotel_id', integrationPropsData.get(`${integrationName}_HOTEL_ID`))
        .set('app_key', integrationPropsData.get(`${integrationName}_APP_KEY`))
        .set('external_system', integrationPropsData.get(`${integrationName}_EXTERNAL_SYSTEM`))
        .set('client_id', integrationPropsData.get(`${integrationName}_CLIENT_ID`))
        .set('client_secret', integrationPropsData.get(`${integrationName}_CLIENT_SECRET`))
        .set('enterprise_id', integrationPropsData.get(`${integrationName}_ENTERPRISE_ID`))
        .set('scope', integrationPropsData.get(`${integrationName}_SCOPE`))

      newIntegrationData = newIntegrationData
        .set('hotel_id', integrationData.get('hotel_id'))
        .set('app_key', integrationData.get('app_key'))
        .set('external_system', integrationData.get('external_system'))
        .set('client_id', integrationData.get('client_id'))
        .set('client_secret', integrationData.get('client_secret'))
        .set('enterprise_id', integrationData.get('enterprise_id'))
        .set('scope', integrationData.get('scope'))
    } else if (integrationName === ACTABL) {
      oldIntegrationData = oldIntegrationData
        .set('hotel_id', integrationPropsData.get(`${integrationName}_HOTEL_ID`))
        .set('api_key', integrationPropsData.get(`${integrationName}_API_KEY`))
        .set('authorization', integrationPropsData.get(`${integrationName}_AUTHORIZATION`))

      newIntegrationData = newIntegrationData
        .set('hotel_id', integrationData.get('hotel_id'))
        .set('api_key', integrationData.get('api_key'))
        .set('authorization', integrationData.get('authorization'))
    }

    if (integrationName === KNOWCROSS || integrationName === KNOWCROSS_V2) {
      if (this.isEditing()) {
        return is(newIntegrationData, oldIntegrationData)
      }
      return this.hasEmptyFields(integrationName === KNOWCROSS ? KNOWCROSS : KNOWCROSS_V2)
    } else if (integrationName === INFOR) {
      if (this.isEditing()) {
        oldIntegrationData = oldIntegrationData.set(
          'endpoint_path',
          integrationPropsData.get(`${integrationName}_ENDPOINT_PATH`)
        )
        oldIntegrationData = oldIntegrationData.set(
          'property_code',
          integrationPropsData.get(`PROPERTY_CODE`)
        )
        newIntegrationData = newIntegrationData.set(
          'endpoint_path',
          integrationData.get('endpoint_path')
        )
        newIntegrationData = newIntegrationData.set(
          'property_code',
          integrationData.get('property_code')
        )

        return is(newIntegrationData, oldIntegrationData)
      }
      return this.hasEmptyFields(INFOR)
    } else if (integrationName === ORACLE_OHIP) {
      if (this.isEditing()) {
        return is(newIntegrationData, oldIntegrationData)
      }
      return this.hasEmptyFields(ORACLE_OHIP)
    } else if (integrationName === ACTABL) {
      if (this.isEditing()) {
        return is(newIntegrationData, oldIntegrationData)
      }
      return this.hasEmptyFields(ACTABL)
    } else if (integrationName === ORACLE_OXI && this.isEditing()) {
      return true
    }
  }

  isPristineforVerify = () => {
    const { integration } = this.state
    const selectedVerifiedIntegration = this.getSelectedInfor()
    if (!integration.get('id')) {
      return true
    } else if (
      integration.get('id') &&
      selectedVerifiedIntegration &&
      selectedVerifiedIntegration.active
    ) {
      return true
    } else {
      return false
    }
  }

  isPristineForStop = () => {
    const selectedVerifiedIntegration = this.getSelectedInfor()
    return selectedVerifiedIntegration && selectedVerifiedIntegration.active ? false : true
  }

  handleVerification = ev => {
    ev && ev.preventDefault()
    const { dispatch, selectedHotelId } = this.props
    const { integration } = this.state
    const source = integration.get('integrationKey')
    this.setState({ verifying: true, error: null })

    dispatch(
      verifyIntegration(selectedHotelId, { source: source }, (statusCode, body) => {
        this.setState({ verifying: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return this.setState({ error: body && fromJS(body.error) })
        }
        this.setState({ selectedVerifiedInfo: fromJS(body.integration_status) })
      })
    )
  }

  handleStop = ev => {
    ev.preventDefault()
    const { dispatch, selectedHotelId } = this.props
    this.setState({ stoping: true, error: null })
    const selectedVerifiedIntegration = this.getSelectedInfor()
    const params = {
      integration_status: {
        active: false
      }
    }
    dispatch(
      stopVerifiedIntegrationProcess(
        selectedHotelId,
        selectedVerifiedIntegration.id,
        params,
        (statusCode, body) => {
          this.setState({ stoping: false })
          if (statusCode !== 200 && statusCode !== 201) {
            return this.setState({ error: body && fromJS(body.error) })
          }
          alertify.success('Stopped SuccesFully')
        }
      )
    )
  }

  getSelectedInfor = () => {
    const { selectedVerifiedInfo } = this.state
    const { hotelVerifiedIntegration } = this.props
    return selectedVerifiedInfo && hotelVerifiedIntegration.get(selectedVerifiedInfo.get('id'))
  }

  getVerfiedStatus = () => {
    const selectedVerifiedIntegration = this.getSelectedInfor()
    const allStatusArray = []
    if (selectedVerifiedIntegration) {
      const status = selectedVerifiedIntegration.status
      if (status.includes('Awaiting')) {
        allStatusArray.push(
          { 'Awaiting Request From Infor': true },
          { 'Incoming received successfully': false },
          { 'Outgoing successfully': false }
        )
      } else if (status.includes('Incoming')) {
        allStatusArray.push(
          { 'Awaiting Request From Infor': true },
          { 'Incoming received successfully': true },
          { 'Outgoing successfully': false }
        )
      } else if (status.includes('Outgoing')) {
        allStatusArray.push(
          { 'Awaiting Request From Infor': true },
          { 'Incoming received successfully': true },
          { 'Outgoing successfully': true }
        )
      }
    }
    return allStatusArray
  }

  render() {
    const { dispatch, selectedHotelId } = this.props
    const { integration, saving, error, verifying } = this.state
    const selectedVerifiedIntegration = this.getSelectedInfor()
    const allStatuses = this.getVerfiedStatus()

    return (
      <React.Fragment>
        <div className="integration-form">
          <div className={`integration-icon ${integration.get('uiKey')}`}>
            <img src={integration.get('logoUrl')} alt="" />
          </div>

          <form className="form-wrapper" autoComplete="off" onSubmit={this.submitForm}>
            <h2 className="form-heading">
              <span>{integration.get('uiName')} </span>
            </h2>

            <FormFields
              dispatch={dispatch}
              integration={integration}
              error={error}
              handleFieldChange={this.handleFieldChange}
              updateIntegration={this.setInitialDataOnEditing}
              isEditing={this.isEditing()}
              hotelId={selectedHotelId}
            />

            {error &&
              error.entrySeq().map(([fieldName, object]) => (
                <div className="error first-letter-capitalize" key={fieldName}>
                  {object.first()}
                </div>
              ))}

            <button type="submit" className="button submit-button" disabled={this.isPristine()}>
              {integration.get('id')
                ? saving
                  ? 'Updating...'
                  : 'Update'
                : saving
                ? 'Creating...'
                : 'Create'}
            </button>
            <button type="cancel" className="button" onClick={this.handleCancel}>
              Cancel
            </button>
          </form>
        </div>
        {integration.get('uiName') === 'Infor' && (
          <div className="verify-button-section">
            <button
              onClick={this.handleVerification}
              disabled={verifying || this.isPristineforVerify()}
              className="button verify-button"
            >
              {verifying ? 'Verifying...' : 'Verify'}
            </button>
            {!this.isPristineForStop() && integration.get('id') && (
              <button
                onClick={this.handleStop}
                disabled={this.isPristineForStop()}
                className="button stop-button button-delete"
              >
                Stop Process
              </button>
            )}
          </div>
        )}
        {!this.isPristineForStop() &&
          integration.get('id') &&
          integration.get('uiName') === 'Infor' &&
          allStatuses.map(status => {
            const statusKey = Object.keys(status)
            return (
              <div className="align-verify-statements">
                <img
                  src={
                    status[statusKey] ? '../icons/success-tick.png' : '../icons/invalid-tick.png'
                  }
                  className="success-icon"
                  alt=""
                />
                <span className="verify-statement">{statusKey}</span>
                {statusKey === 'Outgoing' &&
                  status[statusKey](
                    <span className="verify-statement">Verification Successful !</span>
                  )}
              </div>
            )
          })}
        {!this.isPristineForStop() &&
          integration.get('id') &&
          integration.get('uiName') === 'Infor' && (
            <div className="align-verify-statements">
              <span className="verify-statement">Updated on: </span>
              <span className="verify-statement">
                {selectedVerifiedIntegration &&
                  moment(selectedVerifiedIntegration.started_at).fromNow()}
              </span>
            </div>
          )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  hotelIntegrations: state.hotelIntegrations,
  hotelVerifiedIntegration: state.hotelVerifiedIntegration
})

export default withRouter(connect(mapStateToProps)(HotelIntegration))
