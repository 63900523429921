/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { is, fromJS, Map } from 'immutable'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import Select from 'react-select'
import alertify from 'alertifyjs'
import _ from 'lodash'
import Textarea from 'react-textarea-autosize'

import Classifications from '../constants/Classifications'
import CreateNewBusinessType from './CreateNewBusinessType'
import { loadFeatureFlagsList } from '../actions/AccountActions'
import {
  saveHotel,
  updateHotelLogo,
  deleteHotel,
  loadHotelTimezones
} from '../actions/HotelActions'

class EditHotel extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      hotel: props.hotel
    }
  }

  componentDidMount() {
    const { dispatch, hotelTimezones, featureFlags, hotel } = this.props

    if (hotelTimezones.isEmpty()) {
      dispatch(loadHotelTimezones())
    }

    // Load list of all existing feature-flags
    // Required while creating new business type
    if (featureFlags.isEmpty()) {
      dispatch(loadFeatureFlagsList())
    }

    const lastName = hotel.getIn(['fallback_text', 'last_name'])
    const salutation = hotel.getIn(['fallback_text', 'salutation'])

    this.setState({
      hotel: hotel
        .setIn(['fallback_text', 'last_name'], lastName || null)
        .setIn(['fallback_text', 'salutation'], salutation || null)
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      hotel: nextProps.hotel
    })
  }

  handleChange = ev => {
    let updatedHotel = this.state.hotel.set(ev.target.name, ev.target.value)

    // Auto-populate property size when room count changes
    if (ev.target.name === 'room_count') {
      const roomCount = parseInt(ev.target.value, 10)
      const ranges = [
        [0, 50, '50-or-less'],
        [51, 100, '51-100'],
        [101, 150, '101-150'],
        [151, 200, '151-200'],
        [201, 250, '201-250'],
        [251, Infinity, '251-or-more']
      ]

      const foundRange = ranges.find(([min, max]) => roomCount >= min && roomCount <= max)
      const propertySize = foundRange ? foundRange[2] : ''
      updatedHotel = updatedHotel.set('property_size', propertySize)
    }

    this.setState({
      hotel: updatedHotel,
      error: this.state.error ? this.state.error.delete(ev.target.name) : new Map()
    })
  }

  handleUserChange = ev => {
    const user = this.state.hotel.get('user') || new Map()
    const updatedUser = user.set(ev.target.name, ev.target.value)
    this.setState({
      hotel: this.state.hotel.set('user', updatedUser),
      error: this.state.error ? this.state.error.deleteIn(['user', ev.target.name]) : new Map()
    })
  }

  handleSelectBusinessType = option => {
    const selectedBusinessType = this.props.businessTypes.get(option.value)

    if (!selectedBusinessType) {
      return
    }

    this.setState({
      hotel: this.state.hotel.set('business_type', selectedBusinessType)
    })
  }

  handleSelectTimezone = ({ value }) => {
    this.setState({
      hotel: this.state.hotel.set('hotel_timezone', value)
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const { dispatch, selectedAccountId } = this.props
    const { hotel } = this.state

    const hotelBusinessType = hotel.get('business_type') || new Map()
    const updatedHotelJs = hotel.set('business_type_id', hotelBusinessType.get('id')).toJS()

    this.setState({ isSaving: true, error: null })
    dispatch(
      saveHotel(selectedAccountId, updatedHotelJs, (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200 && statusCode !== 201) {
          this.setState({
            error: fromJS(body)
          })
          const topElement = document.getElementsByTagName('input')[0]
          topElement && topElement.scrollIntoView()
          return
        }

        alertify.success(`Hotel successfully ${hotel.get('id') ? 'updated' : 'created'}.`)
        setTimeout(() => {
          const hotelId = body && body.hotel && body.hotel.id
          dispatch(push(`/account/${selectedAccountId}/hotel/${hotelId}`))
        }, 100)
      })
    )
  }

  handleLogoUpload = ev => {
    ev.preventDefault()
    const { dispatch, selectedAccountId } = this.props
    const { hotel } = this.state

    const hotelId = hotel.get('id')
    const files = ev.target.files

    let formData = new FormData()
    files && formData.append('logo', files[0])

    this.setState({ isLoadingLogo: true, errorLogoUpdate: null })
    dispatch(
      updateHotelLogo(selectedAccountId, hotelId, formData, (statusCode, body) => {
        this.setState({ isLoadingLogo: false })
        if (statusCode !== 200) {
          return this.setState({ errorLogoUpdate: fromJS(body) })
        }

        // Unset/Remove the uploaded file from input handler
        this.hotelLogoInput.value = ''
      })
    )
  }

  handleDelete = ev => {
    ev.preventDefault()
    const confirmed = window.confirm('Are you sure you want to delete this hotel?')
    if (!confirmed) return

    const { dispatch, selectedAccountId } = this.props
    const { hotel } = this.state

    this.setState({ isDeleting: true, error: null })
    dispatch(
      deleteHotel(selectedAccountId, hotel.get('id'), (statusCode, body) => {
        this.setState({ isDeleting: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return this.setState({ error: body && body.error })
        }
        dispatch(push(`/account/${selectedAccountId}`))
      })
    )
  }

  handleChangeClassification = ev => {
    const classification = parseInt(ev.target.value, 10)

    this.setState({
      hotel: this.state.hotel.set('classification', classification)
    })
  }

  handleFallbackChange = ev => {
    const { hotel } = this.state
    this.setState({
      hotel: hotel.setIn(
        ['fallback_text', ev.target.name],
        ev.target.value || (ev.target.value === '' && null)
      )
    })
  }

  handleReset = ev => {
    ev.preventDefault()
    this.setState({ hotel: this.props.hotel })
  }

  isPristine = () => {
    const { isSaving } = this.state

    return is(this.state.hotel, this.props.hotel) || isSaving
  }

  addNewBusinessType = () => {
    this.setState({ showCreateBusinessTypeModal: true })
  }

  closeCreateBusinessTypeModal = () => {
    this.setState({ showCreateBusinessTypeModal: false })
  }

  getErrorMessage = (error, key) => {
    if (error.isEmpty()) return ''
    let errorMsg = error.first()
    return errorMsg.startsWith(key) ? errorMsg : `${key} ${errorMsg}`
  }

  getPropertySizeLabel = value => {
    const options = {
      '50-or-less': '50 Rooms or Less',
      '51-100': '51 to 100 Rooms',
      '101-150': '101 to 150 Rooms',
      '151-200': '151 to 200 Rooms',
      '201-250': '201 to 250 Rooms',
      '251-or-more': '251 Rooms or More'
    }
    return options[value] || ''
  }

  render() {
    const { hotelTimezones, businessTypes, featureFlags } = this.props
    const { hotel, isLoadingLogo, error, errorLogoUpdate, showCreateBusinessTypeModal } = this.state

    const mappedTimezones =
      hotelTimezones &&
      hotelTimezones.map(
        timezone =>
          new Map({
            value: timezone.get('name'),
            label: `${timezone.get('name')} (${timezone.get('offset')})`
          })
      )

    const selectedTimezone =
      mappedTimezones &&
      mappedTimezones.filter(tz => tz.get('value') === hotel.get('hotel_timezone')).first()

    const user = (hotel && hotel.get('user')) || new Map()

    const mappedBusinessTypes = businessTypes
      .map(
        type =>
          new Map({
            value: type.get('id'),
            label: type.get('name')
          })
      )
      .toList()

    const hotelBusinessType = hotel.get('business_type') || new Map()
    const selectedBusinessType = new Map({
      value: hotelBusinessType.get('id'),
      label: hotelBusinessType.get('name')
    })

    const fallbackData = hotel.get('fallback_text')

    return (
      <form className="settings-container hotel-settings lpadding9em" onSubmit={this.handleSubmit}>
        <div className="hotel-avatar avatar">
          {isLoadingLogo ? (
            <img src="/icons/ring.gif" alt="" className="uploading-logo" />
          ) : (
            <img src={hotel.get('logo')} alt="" />
          )}
        </div>
        <h1>
          <input
            type="text"
            name="name"
            value={hotel.get('name') || ''}
            onChange={this.handleChange}
            placeholder="Business Name"
          />
        </h1>

        {hotel.get('id') && (
          <fieldset>
            <div className="logo-upload-wrapper">
              <label className="photo photo-input button button-secondary">
                <div className="photo-input__placeholder">
                  <strong>Upload new business logo</strong>
                </div>
                <input
                  type="file"
                  id="hotel-logo"
                  name="hotel-logo"
                  accept=".png,.jpg,.jpeg,.gif"
                  ref={input => {
                    this.hotelLogoInput = input
                  }}
                  onChange={this.handleLogoUpload}
                />
              </label>

              {errorLogoUpdate && errorLogoUpdate.has('logo') && (
                <div className="error">Logo {errorLogoUpdate.get('logo')}</div>
              )}
              {errorLogoUpdate && errorLogoUpdate.has('logo_content_type') && (
                <div className="error">
                  Logo content type {errorLogoUpdate.get('logo_content_type')}
                </div>
              )}
            </div>
          </fieldset>
        )}

        <fieldset>
          <legend>Business Type</legend>
          <div className="business-type-select">
            <Select
              value={selectedBusinessType && selectedBusinessType.toJS()}
              options={mappedBusinessTypes.toJS()}
              onChange={this.handleSelectBusinessType}
            />
            <button
              type="button"
              className="button button-secondary button-small"
              onClick={this.addNewBusinessType}
            >
              Add New Business Type
            </button>
          </div>
        </fieldset>

        <fieldset>
          <legend>Contact Info</legend>
          <div className="w-50pct">
            <input
              type="text"
              name="phone"
              value={hotel.get('phone') || ''}
              onChange={this.handleChange}
              placeholder="Phone Number"
            />
          </div>
          <div className="w-50pct">
            <input
              type="email"
              name="email"
              value={hotel.get('email') || ''}
              onChange={this.handleChange}
              placeholder="Email ID"
              className={error && error.get('email') ? 'error' : ''}
            />
          </div>
          {error && error.get('email') && (
            <div className="error">{this.getErrorMessage(error.get('email'), 'Email')}</div>
          )}
          <div className="w-50pct">
            <input
              type="url"
              name="website"
              value={hotel.get('website') || ''}
              onChange={this.handleChange}
              placeholder="Website URL"
            />
          </div>
        </fieldset>

        <fieldset id="address">
          <legend>Address</legend>
          <input
            placeholder="Street Address"
            type="text"
            name="address"
            value={hotel.get('address') || ''}
            onChange={this.handleChange}
          />
          <input
            placeholder="City"
            type="text"
            name="city"
            value={hotel.get('city') || ''}
            onChange={this.handleChange}
          />
          <input
            placeholder="State/Province"
            type="text"
            name="state"
            value={hotel.get('state') || ''}
            onChange={this.handleChange}
          />
          <input
            placeholder="Country"
            type="text"
            name="country"
            value={hotel.get('country') || ''}
            onChange={this.handleChange}
          />
          <input
            placeholder="Postal Code"
            type="text"
            name="postal"
            value={hotel.get('postal') || ''}
            onChange={this.handleChange}
          />
        </fieldset>
        <div className="display-flex">
          <fieldset className="w-50pct" style={{ marginRight: '20px' }}>
            <legend>Room Count</legend>
            <input
              placeholder=""
              type="number"
              name="room_count"
              value={hotel.get('room_count') || ''}
              onChange={this.handleChange}
            />
          </fieldset>
          <fieldset className="w-50pct">
            <legend>Property Size</legend>
            <Select
              name="property_size"
              value={{
                value: hotel.get('property_size') || '',
                label: this.getPropertySizeLabel(hotel.get('property_size'))
              }}
              onChange={selectedOption => {
                this.handleChange({
                  target: {
                    name: 'property_size',
                    value: selectedOption ? selectedOption.value : ''
                  }
                })
              }}
              options={[
                { value: '50-or-less', label: '50 Rooms or Less' },
                { value: '51-100', label: '51 to 100 Rooms' },
                { value: '101-150', label: '101 to 150 Rooms' },
                { value: '151-200', label: '151 to 200 Rooms' },
                { value: '201-250', label: '201 to 250 Rooms' },
                { value: '251-or-more', label: '251 Rooms or More' }
              ]}
              isClearable={true}
              placeholder="Select Property Size"
            />
          </fieldset>
        </div>
        <fieldset className="w-50pct">
          <legend>Internal Code</legend>
          <input
            placeholder=""
            type="text"
            name="internal_code"
            value={hotel.get('internal_code') || ''}
            onChange={this.handleChange}
          />
        </fieldset>

        <fieldset className="w-50pct">
          <legend>Type</legend>
          <input
            placeholder=""
            type="text"
            name="hotel_type"
            value={hotel.get('hotel_type') || ''}
            onChange={this.handleChange}
          />
        </fieldset>

        <fieldset className="w-50pct">
          <legend>Timezone</legend>
          <Select
            value={selectedTimezone && selectedTimezone.toJS()}
            options={mappedTimezones.toJS()}
            onChange={this.handleSelectTimezone}
          />
        </fieldset>

        <fieldset id="classification">
          <legend>Classifications</legend>
          <div className="input-grid">
            {Classifications.map((classification, i) => (
              <label key={i}>
                <input
                  type="radio"
                  name="classification"
                  value={i}
                  onChange={this.handleChangeClassification}
                  checked={hotel.get('classification') === i}
                />
                {classification}
              </label>
            ))}
          </div>
        </fieldset>

        <fieldset className="form-item">
          <legend>Unsupported message</legend>
          <textarea
            name="unsupported_message"
            onChange={this.handleChange}
            value={hotel.get('unsupported_message') || ''}
            className={error && error.has('unsupported_message') ? 'error' : ''}
          />
        </fieldset>

        <fieldset className="w-50pct">
          <legend>Fallback for Salutation (Moments and Broadcasts)</legend>
          <input
            placeholder=""
            type="text"
            name="salutation"
            value={fallbackData.get('salutation') || ''}
            onChange={this.handleFallbackChange}
          />
        </fieldset>

        <fieldset className="w-50pct">
          <legend>Fallback for Last Name (Moments and Broadcasts)</legend>
          <input
            placeholder=""
            type="text"
            name="last_name"
            value={fallbackData.get('last_name') || ''}
            onChange={this.handleFallbackChange}
          />
        </fieldset>

        {error && (
          <fieldset id="errors">
            {error.entrySeq().map(
              ([key, value]) =>
                key !== 'email' &&
                key !== 'user' && (
                  <div key={key} className="error">
                    <span className="text-capitalize">{_.replace(key, /_/g, ' ')} </span>
                    <span>{value}.</span>
                  </div>
                )
            )}
          </fieldset>
        )}

        {!hotel.get('id') && (
          <div className="reserved-user-wrapper">
            <legend>Reserved User</legend>

            <div className="name">
              <input
                className={error && error.getIn(['user', 'first_name']) ? 'error' : ''}
                placeholder="First name..."
                type="text"
                name="first_name"
                value={user.get('first_name') || ''}
                onChange={this.handleUserChange}
              />
              {error && error.getIn(['user', 'first_name']) && (
                <div className="error">
                  {this.getErrorMessage(error.getIn(['user', 'first_name']), 'First Name')}
                </div>
              )}

              <input
                className={error && error.getIn(['user', 'last_name']) ? 'error' : ''}
                placeholder="Last name..."
                type="text"
                name="last_name"
                value={user.get('last_name') || ''}
                onChange={this.handleUserChange}
              />
              {error && error.getIn(['user', 'last_name']) && (
                <div className="error">
                  {this.getErrorMessage(error.getIn(['user', 'last_name']), 'Last Name')}
                </div>
              )}

              <input
                className={error && error.getIn(['user', 'title']) ? 'error' : ''}
                placeholder="Title..."
                type="text"
                name="title"
                value={user.get('title') || ''}
                onChange={this.handleUserChange}
              />
              {error && error.getIn(['user', 'title']) && (
                <div className="error">
                  {this.getErrorMessage(error.getIn(['user', 'title']), 'Title')}
                </div>
              )}
            </div>

            <div className="w-50pct">
              <input
                className={error && error.getIn(['user', 'email']) ? 'error' : ''}
                placeholder="Email address..."
                type="text"
                name="email"
                value={user.get('email') || ''}
                onChange={this.handleUserChange}
              />
              {error && error.getIn(['user', 'email']) && (
                <div className="error">
                  {this.getErrorMessage(error.getIn(['user', 'email']), 'Email')}
                </div>
              )}
            </div>

            <div className="password">
              <input
                className={error && error.getIn(['user', 'password']) ? 'error' : ''}
                placeholder="Password..."
                type="password"
                name="password"
                value={user.get('password') || ''}
                onChange={this.handleUserChange}
              />
              {error && error.getIn(['user', 'password']) && (
                <div className="error">
                  {this.getErrorMessage(error.getIn(['user', 'password']), 'Password')}
                </div>
              )}

              <input
                className={error && error.getIn(['user', 'password_confirmation']) ? 'error' : ''}
                placeholder="Confirm password..."
                type="password"
                name="password_confirmation"
                value={user.get('password_confirmation') || ''}
                onChange={this.handleUserChange}
              />
              {error && error.getIn(['user', 'password_confirmation']) && (
                <div className="error">
                  {this.getErrorMessage(error.getIn(['user', 'password_confirmation']), 'Password')}
                </div>
              )}
            </div>
          </div>
        )}

        <fieldset className="tmargin2em">
          <button disabled={this.isPristine()} type="submit" className="button">
            Save
          </button>
          <button type="cancel" className="button" onClick={this.handleReset}>
            Reset
          </button>
          {
            // hotel.get('id') && (
            //   <div className="pull-right">
            //     <button
            //       disabled={isDeleting}
            //       type="button"
            //       className="button button-delete delete pull-right"
            //       onClick={this.handleDelete}
            //     >
            //       Delete
            //     </button>
            //   </div>
            // )
          }
        </fieldset>

        {showCreateBusinessTypeModal && (
          <CreateNewBusinessType
            isOpen={showCreateBusinessTypeModal}
            onRequestClose={this.closeCreateBusinessTypeModal}
            featureFlags={featureFlags || new Map()}
          />
        )}
      </form>
    )
  }
}

const mapStateToProps = state => ({
  hotelTimezones: state.hotelTimezones,
  businessTypes: state.businessTypes,
  featureFlags: state.featureFlags
})

export default connect(mapStateToProps)(EditHotel)
