/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, September 2019
 */

import { fromJS } from 'immutable'

const HotelIntegrationConstants = fromJS([
  {
    integrationKey: 'BOOK4TIME',
    integration_type: 3018,
    uiName: 'Book4Time',
    logoUrl: '/icons/book4time.png',
    integration_data: {
      base_url: 'https://api.na.book4time.com/Book4TimeAPI2',
      account_token: undefined,
      api_token: undefined,
      login_id: undefined,
      password: undefined,
      machine_id: undefined,
      num_days_till_token_exp: 10000
    }
  },
  {
    integrationKey: 'KNOWCROSS',
    integration_type: 3007,
    uiName: 'Knowcross',
    logoUrl: '/icons/knowcross.png',
    integration_data: {
      username: undefined,
      password: undefined,
      endpoint: undefined
    }
  },
  {
    integrationKey: 'KNOWCROSS_V2',
    integration_type: 3014,
    uiName: 'Knowcross V2',
    logoUrl: '/icons/knowcross.png',
    integration_data: {
      property_id: undefined,
      public_key: undefined,
      private_key: undefined,
      endpoint: undefined
    }
  },
  {
    integrationKey: 'ACTABL',
    integration_type: 3012,
    uiName: 'Actabl',
    logoUrl: '/icons/actabl.png',
    integration_data: {
      username: undefined,
      password: undefined,
      endpoint: undefined,
      hotel_id: undefined,
      api_key: undefined,
      authorization: undefined
    }
  },
  {
    integrationKey: 'INFOR',
    integration_type: 3005,
    uiName: 'Infor',
    logoUrl: '/icons/infor.jpg',
    integration_data: {
      username: undefined,
      password: undefined,
      endpoint: undefined
    }
  },
  {
    integrationKey: 'ORACLE_OXI',
    integration_type: 3009,
    uiName: 'Oracle OXI',
    logoUrl: '/icons/oracle-oxi.png',
    integration_data: {
      username: undefined,
      password: undefined,
      endpoint: undefined
    }
  },
  {
    integrationKey: 'ORACLE_OHIP',
    integration_type: 3011,
    uiName: 'Oracle OHIP',
    logoUrl: '/icons/oracle-ohip.png',
    integration_data: {
      username: undefined,
      password: undefined,
      endpoint: undefined,
      hotel_id: undefined,
      app_key: undefined,
      external_system: undefined,
      client_id: undefined,
      client_secret: undefined
    }
  },
  {
    integrationKey: 'SEVENROOMS',
    integration_type: 3013,
    uiName: 'SevenRooms',
    logoUrl: '/icons/seven_rooms.png',
    integration_data: {
      venue_group_id: undefined,
      client_id: undefined,
      client_secret: undefined
    }
  },
  {
    integrationKey: 'SHIJI',
    integration_type: 3015,
    uiName: 'Shiji',
    logoUrl: '/icons/shiji.png',
    integration_data: {
      username: undefined,
      password: undefined,
      endpoint: undefined,
      hotel_id: undefined,
      channel_id: undefined,
      api_key: undefined,
      api_secret: undefined
    }
  },
  {
    integrationKey: 'MESSAGE_BOX',
    integration_type: 3017,
    uiName: 'MessageBox',
    logoUrl: '/icons/message_box.png',
    integration_data: {
      endpoint: undefined,
      authorization: undefined,
      location_id: undefined,
      user_id: undefined
    }
  },
  {
    integrationKey: 'CENDYN',
    integration_type: 3019,
    uiName: 'Cendyn',
    logoUrl: '/icons/cendyn.png',
    integration_data: {
      account_id: undefined,
      api_secret: undefined,
      api_master_secret: undefined,
      endpoint: undefined
    }
  }
])

export default HotelIntegrationConstants
